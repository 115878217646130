import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCcMastercard, faCcVisa } from '@fortawesome/free-brands-svg-icons'
import LogoMeci from '../images/logo_nocopy.svg'
import { Link } from 'gatsby'

const Footer = () => (
  <footer className='d-flex justify-content-center align-items-center mt-auto py-3'>
    <div className='text-center'>
      <img
        src={LogoMeci}
        alt='Logo MECI'
        layout='fixed'
        width='140'
        className='mb-1'
      />
      <div>
        <p>
          Sonora 768 Sur, Col. Nuevo Repueblo
          <br />
          Monterrey, Nuevo León, México, C.P. 64700
          <br />
          Tel. 81-1534-9214
          <br />
          <a className='orange-link' href='mailto:ventas@meci.mx'>
            ventas@meci.mx
          </a>
        </p>
        <hr />
        {/* <p>
        Lic. Edith Guillen
        <br />
        Ventas a Gobierno y Licitaciones
        <br />
        +52 56-1174-2199
        <br />
        <a className='orange-link' href="mailto:eguillen@mercantil.mx">eguillen@mercantil.mx</a>
      </p> */}
        <p>
          <Link className='orange-link' to='/privacidad'>
            Aviso de privacidad
          </Link>
        </p>
      </div>
      <FontAwesomeIcon className='fa-3x mx-2' icon={faCcVisa} />
      <FontAwesomeIcon className='fa-3x mx-2' icon={faCcMastercard} />
    </div>
  </footer>
)

export default Footer
