import React from 'react'
import { Link } from 'gatsby'
import LogoMeci from '../images/meci-amerex-logo-b.svg'
// import LogoMeci from '../images/logo_original.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'

const Navigation = () => (
  <div className='d-flex flex-wrap flex-md-nowrap flex-md-column'>
    <div className='d-flex justify-content-center align-items-center col-12 p-2 bg-site-orange'>
      <p className='text-white text-center m-0'>
        Llame, envíe un correo o un mensaje para resolver sus dudas.
        <br />{' '}
        <span className='fw-bold lead'>
          Tel (81) 1534-9214 | ventas@meci.mx | Cel (+52) 81-3551-4315
        </span>
        <br />
        <span className='small fst-italic'>
          Mercantil de Equipos Contra Incendio S. de R.L. de C.V.
        </span>
      </p>
    </div>
    <div className='col-12 d-flex flex-column flex-md-row align-items-center justify-content-between px-5 py-3 bg-white'>
      <div>
        <Link to='/'>
          <img
            src={LogoMeci}
            width='220'
            alt='Logotipo de MECI'
            className='mb-4 mb-md-0'
          />
        </Link>
      </div>
      <div className='text-site-orange'>
        <a
          href='https://www.facebook.com/MECCIMX/'
          target='_blank'
          rel='noreferrer noopener'
          className='orange-link'
        >
          <FontAwesomeIcon
            className='fa-3x mx-2'
            icon={faFacebook}
            aria-label='Facebook'
          />
        </a>

        <a
          href='https://www.instagram.com/mercantildeequipos/'
          target='_blank'
          rel='noreferrer noopener'
          className='orange-link'
        >
          <FontAwesomeIcon
            className='fa-3x mx-2'
            icon={faInstagram}
            aria-label='Instagram'
          />
        </a>

        <a
          href='https://twitter.com/Meccimx'
          target='_blank'
          rel='noreferrer noopener'
          className='orange-link'
        >
          <FontAwesomeIcon
            className='fa-3x mx-2'
            icon={faTwitter}
            aria-label='WhatsApp'
          />
        </a>

        <a
          href='https://wa.me/message/M55IVMFYJGL4A1'
          target='_blank'
          rel='noreferrer noopener'
          className='orange-link'
        >
          <FontAwesomeIcon
            className='fa-3x mx-2'
            icon={faWhatsapp}
            aria-label='WhatsApp'
          />
        </a>
      </div>
    </div>
    <nav className='navbar navbar-expand-lg navbar-light'>
      <div className='container-fluid'>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span> MENU
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            <li className='nav-item dropdown'>
              <Link
                className='nav-link dropdown-toggle'
                to='/'
                id='navbarDropdown'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                EXTINTORES
              </Link>
              <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <li>
                  <Link className='dropdown-item' to='/portatiles'>
                    Extintores portátiles nacionales
                  </Link>
                </li>
                <li>
                  <Link className='dropdown-item' to='/portatiles-amerex'>
                    Extintores portátiles Amerex
                  </Link>
                </li>
                <li>
                  <Link className='dropdown-item' to='/moviles'>
                    Unidades móviles nacionales
                  </Link>
                </li>
                <li>
                  <Link className='dropdown-item' to='/moviles-amerex'>
                    Unidades móviles Amerex
                  </Link>
                </li>
                <li>
                  <hr className='dropdown-divider' />
                  <p className='fs-6 fw-bold ms-3 mb-0 text-site-red'>
                    OTROS TIPOS
                  </p>
                </li>
                <li>
                  <Link className='dropdown-item' to='/detectores'>
                    Extintores de incendio automáticos
                  </Link>
                </li>
              </ul>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/sistemas'>
                Sistemas
              </Link>
            </li>
            {/* <li className='nav-item dropdown'>
            <Link
              className='nav-link dropdown-toggle'
              to='/'
              id='navbarDropdown'
              role='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              Dropdown
            </Link>
            <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
              <li>
                <Link className='dropdown-item' to='/'>
                  Action
                </Link>
              </li>
              <li>
                <Link className='dropdown-item' to='/'>
                  Another action
                </Link>
              </li>
              <li>
                <hr className='dropdown-divider' />
              </li>
              <li>
                <Link className='dropdown-item' to='/'>
                  Something else here
                </Link>
              </li>
            </ul>
          </li> */}
            <li className='nav-item dropdown'>
              <Link
                className='nav-link dropdown-toggle'
                to='/'
                id='navbarDropdown'
                role='button'
                data-bs-toggle='dropdown'
                aria-expanded='false'
              >
                ACCESORIOS/EQUIPO DE TALLER
              </Link>
              <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                <li>
                  <Link className='dropdown-item' to='/accesorios'>
                    Mangueras y accesorios
                  </Link>
                </li>
                <li>
                  <Link className='dropdown-item' to='/equipo-taller'>
                    Equipo para taller
                  </Link>
                </li>
                {/* <li>
                  <hr className='dropdown-divider' />
                  <p className='fs-6 fw-bold ms-3 mb-0 text-site-red'>
                    OTROS TIPOS
                  </p>
                </li>
                <li>
                  <Link className='dropdown-item' to='/detectores'>
                    Detectores de incendio automáticos
                  </Link>
                </li> */}
              </ul>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/gabinetes'>
                Gabinetes
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/bomberos'>
                Bomberos
              </Link>
            </li>
            <li className='nav-item'>
              <Link className='nav-link' to='/espumas'>
                Espumas
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
)

export default Navigation
