import React from 'react'
import Navigation from './navigation'
import Footer from './footer'
import { config } from '@fortawesome/fontawesome-svg-core'

config.autoAddCss = false

export default function Layout({ children }) {
  return (
    <div className='col-12 d-flex flex-column'>
      <Navigation />
      <div>{children}</div>
      <Footer />
    </div>
  )
}
